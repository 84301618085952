<!-- SH消息学院 -->
<template>
  <div class="Solution">
    <section class="section-1">
      <div class="page">
        <div class="title">更多精彩/更智能</div>
        <div class="sub-title">
          5G消息增加了全新的MaaP消息形态，通过Chatbot智能化服务，实现应用与个人交互，在消息窗口完成搜索/发现/订阅/支付等业务体验。
        </div>
      </div>
    </section>
    <section class="section-2">
      <div class="page">
        <div class="title">丰富的消息模板/无需任何编码</div>
        <div class="sub-title">轻松创建5G消息和chatbot交互场景</div>
      </div>
    </section>
    <section class="section-3">
      <div class="page">
        <div class="title">接入平台流程</div>
        <div class="sub-title">我们用先进的技术与服务为开发者创造更好体</div>
      </div>
    </section>
    <section class="section-4">
      <div class="page">
        <div class="title">安全与认证</div>
        <div class="sub-title">为开发者们提供具备安全保障的可靠服务</div>
      </div>
    </section>
    <section class="section-5">
      <div class="page">
        <div class="title">新闻中心</div>
        <div class="sub-title">News Center</div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Solution'
}
</script>
<style lang="scss" scoped>
.section-1 {
  height: 820px;
  text-align: center;
  .title {
    margin-top: 63px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
  }
}
.section-2 {
  overflow: hidden;
  background: #173dba;
  height: 820px;
  text-align: center;
  .title {
    margin-top: 100px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.8;
  }
}
.section-3 {
  overflow: hidden;
  height: 805px;
  text-align: center;
  .title {
    margin-top: 175px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}

.section-4 {
  overflow: hidden;
  background: #173dba;
  height: 600px;
  text-align: center;
  .title {
    margin-top: 102px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.8;
  }
}
.section-5 {
  overflow: hidden;
  height: 880px;
  text-align: center;
  .title {
    margin-top: 175px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 10px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}
</style>
