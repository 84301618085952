<template>
  <router-view />
</template>
<style>
/* css初始化 */
@import "../static/normalize.css";
/* 公共样式 */
@import "../static/free.css";
/* 公共样式 */
@import "../static/common.css";
/* @import "../static/animate.min.css"; */
</style>
<style lang="scss"></style>
