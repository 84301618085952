<template>
  <div class="header" :class="isFlag ? 'flag' : ''">
    <div class="page flex justify-between">
      <img v-if="isFlag" :src="require('../assets/images/home/logo2.png')" alt="" />
      <img v-else :src="require('../assets/images/home/logo.png')" alt="" />
      <!-- <div class="header-l">
        <span>5G消息云平台备份</span>
      </div> -->
      <div class="headerIco" @click="menuClick()">
        <img v-if="isFlag" :src="require('../assets/images/home/menu1.png')" alt="" />
        <img v-else :src="require('../assets/images/home/menu.png')" alt="" />
      </div>
      <div class="header-r" :class="[isMenu ? 'phone-header-r' : '', !isFlag ? 'headerColor' : '']">
        <!-- 导航 -->
        <div class="close" @click="menuClick()">
          <img src="../assets/images/home/close.png" alt="" />
        </div>
        <div class="header-nav">
          <div
            v-for="item in navMenu"
            :key="item.path"
            :class="[isActive(item) ? 'active' : '', 'operation-list']"
            @click="handelMenu(item)"
          >
            <div>{{ item.name }}</div>
            <!-- <img class="header-img" v-if="item.iconSrc" :src="require('../assets/images/home/iconTips.png')" alt="" /> -->
            <div v-if="item.children" class="navMenuChildren">
              <template v-for="(items, index) in item.children" :key="index">
                <div @click.stop="toClick(item.path, index)" :class="index === cacheIndex ? 'childSelected' : ''">
                  {{ items.name }}
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- 登录注册 -->
      </div>
      <!-- <div><span class="gologin" @click="goLogin">马上试用</span></div> -->
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      navMenu: [
        {
          path: "/home",
          name: "首页",
        },
        {
          path: "/ProductService",
          name: "产品服务",
        },
        {
          path: "/Solution",
          name: "解决方案",
          children: [
            { name: "教育领域" },
            { name: "金融领域" },
            { name: "电力领域" },
            { name: "防灾预警" },
            { name: "电商领域" },
            { name: "智慧家庭" },
            { name: "娱乐领域" },
            { name: "传媒领域" },
          ],
        },
        {
          path: "/ScenarioCase",
          name: "场景案例",
        },
        {
          path: "#",
          // path: '/shMsg',
          name: "5G开放平台",
          isIcon: true,
          iconSrc: require("../assets/images/home/iconTips.png"),
        },
        // {
        //   path: '#',
        //   // path: '/about',
        //   name: '关于我们'
        // }
      ],
      isMenu: false,
    };
  },
  computed: {
    isFlag () {
      return this.$route.path == "/home";
    },
    isActive () {
      return (route) => route.path === this.$route.path;
    },
    cacheIndex () {
      return this.$store.state.childSelected;
    },
  },
  mounted () { },
  methods: {
    // 路由跳转
    handelMenu (item) {
      this.isMenu = false;
      if (item.name === "5G开放平台") {
        // window.location.href = "https://5gcloud.unioncast.cn/cloudWebClient";
        window.open("https://5gcloud.unioncast.cn/cloudWebClient");
      } else {
        this.$store.commit("SETCHILDSELECTED", "");
        this.$router.push(`${item.path}`);
      }
    },
    goLogin () {
      this.$router.push("/login");
    },
    toClick (path, index) {
      this.isMenu = false;
      this.$store.commit("SETCHILDSELECTED", index);
      var d = new Date(); //定义时间戳
      this.$router.push({
        path: `${path}`,
        query: { t: d.getTime() },
      });
    },
    menuClick () {
      this.isMenu = !this.isMenu;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  align-items: center;
}

.header {
  height: 66px;
  line-height: 66px;
  background: #ffffff;
  img {
    line-height: 66px;
    width: 230px;
  }
  .header-l {
    color: #222222;
    font-size: 18px;
    font-weight: bold;
    line-height: 66px;
  }
  .header-r {
    .header-nav {
      font-size: 18px;
      display: flex;
    }
    .operation-list {
      cursor: pointer;
      padding: 0 20px;
      position: relative;
      .header-img {
        position: absolute;
        top: 50%;
        right: -5px;
        width: 44px;
        height: 16px;
        transform: translate(50%, -50%);
      }
      .navMenuChildren {
        background-color: #fff;
        position: absolute;
        z-index: 999;
        width: 100%;
        left: 0;
        text-align: center;
        color: #222222;
        display: none;
        div {
          line-height: 50px;
        }
        div:hover {
          color: #597ef7;
        }
        .childSelected {
          background-color: #597ef7;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
      &:hover .navMenuChildren {
        display: block;
      }
    }
    .active {
      color: #597ef7;
    }
  }
  .gologin {
    width: 115px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #597ef7;
    line-height: 22px;
    cursor: pointer;
  }
  .gologin:hover {
    color: rgb(93, 176, 235);
    transition: 1s;
  }
  .headerIco {
    width: 150px;
    display: none;
    padding-top: 16px;
    img {
      width: 100%;
    }
  }
  .close {
    width: 120px;
    display: none;
    img {
      width: 100%;
    }
    position: absolute;
    right: 80px;
    top: 40px;
    z-index: 9999;
  }
}
.flag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: rgba(255, 255, 255, 0);
  color: #eaf4fe;
}
.Disable {
  color: #999;
  cursor: not-allowed !important;
}
@media screen and (max-width: 750px) {
  .header {
    height: 200px;
    line-height: 200px;
    .page {
      height: 200px;
    }
    .headerIco,
    .close {
      display: block;
    }
    img {
      width: 630px;
      height: auto;
    }

    .header-r {
      display: none;
      .header-nav {
        font-size: 54px;
        display: flex;
      }
      .operation-list {
        .navMenuChildren {
          div {
            line-height: 100px;
          }
        }
      }
      &.phone-header-r {
        display: block;
        position: fixed;
        background: rgba(0, 0, 0, 0.9);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        box-sizing: border-box;
        padding: 100px;
        z-index: 999;
        .header-nav {
          font-size: 80px;
          display: block;
          .operation-list {
            .navMenuChildren {
              display: block;
              background: none;
              position: relative;
              color: #fff;
              text-align: left;
              padding-left: 80px;
              & > div {
                line-height: 230px;
              }
            }
          }
        }
        &.headerColor {
          color: #fff;
        }
      }
    }
  }
}
</style>
