<!-- 产品服务 -->
<template>
  <div class="ProductService">
    <div class="header">
      <img
        :src="require('../assets/images/ProductService/banner.png')"
        alt=""
      />
      <!-- <div class="content">
        <div class="title">5G消息基于GSMA RCS UP2.4国际通讯标准</div>
        <div class="sub-title">
          支持图文消息、多媒体消息、会话消息、增值服务等服务形态
        </div>
      </div> -->
    </div>
    <section class="section-1">
      <div class="page">
        <div class="title">5G消息场景制作</div>
        <!-- <div class="sub-title">5G消息制作&发送</div> -->
        <!-- swiper -->
        <div class="swiper-container">
          <!-- 需要进行轮播的部分 -->
          <div class="swiper-wrapper">
            <!-- 每个节点 -->
            <div
              class="swiper-slide"
              v-for="item in bannerData"
              :key="item.name"
            >
              <div class="content" :class="item.type == 2 ? 'content2' : ''">
                <div class="content-l">
                  <img :src="item.pic" alt="图片1" />
                </div>
                <div class="content-r">
                  <div class="list" v-for="text in item.list" :key="text.title">
                    <div class="list-title">{{ text.title }}</div>
                    <div class="list-content">{{ text.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--如果需要使用分页器-->
          <div class="swiper-pagination swiper-pagination-black"></div>

          <!-- 如果需要使用前进后退按钮 -->
          <div class="swiper-button-prev swiper-button-black"></div>
          <div class="swiper-button-next swiper-button-black"></div>
        </div>
      </div>
    </section>
    <section class="section-2">
      <div class="page">
        <div class="title">5G消息特色优势</div>
        <div class="content">
          <div
            class="content-list"
            v-for="item in section2Data"
            :key="item.name"
          >
            <img :src="item.pic" alt="" />
            <div class="text">
              <div class="t">{{ item.name }}</div>
              <div class="st">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-3">
      <div class="page">
        <div class="title">开发openAPI</div>
        <div class="content">
          <div
            class="content-list"
            v-for="item in section3Data"
            :key="item.name"
          >
            <img :src="item.pic" alt="" />
            <div class="text">
              <div class="t">{{ item.name }}</div>
              <div class="st">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Swiper from 'swiper'
import '../../node_modules/swiper/dist/css/swiper.min.css'
import '../../node_modules/swiper/dist/js/swiper.min.js'
export default {
  name: 'ProductService',
  data() {
    return {
      bannerData: [
        {
          type: 1,
          pic: require('../assets/images/ProductService/section1-1.png'),
          list: [
            {
              title: '功能丰富/选择多样',
              content:
                '支持文本消息、卡片消息、多卡片消息、文件消息、可配置固定菜单、悬浮菜单，设置消息回落，满足您的需求。'
            },
            {
              title: '海量模板/一键应用',
              content:
                '用户可以筛选行业模板，选择适用的模板一键应用，可快速实现chatbot行业场景应用，降低运营制作成本。'
            }
          ]
        },
        {
          type: 2,
          pic: require('../assets/images/ProductService/section1-2.png'),
          list: [
            {
              title: 'Chatbot智能交互/体验更优',
              content:
                '基于AI人工智能应用，通过上下行会话式交互实现服务闭环，根据会话式交互服务场景为用户提供多种智能化服务。'
            },
            {
              title: '多样式回落/覆盖更广',
              content:
                '5G多媒体消息（标准版）作为5G行业消息子产品，具备覆盖全量智能终端、可发送多媒体消息的优势。目前支持的回落的几种消息形态：文本短信回落，chatboth5回落，阅信回落（小程序2.0回落），视频短信回落（彩信回落）。'
            }
          ]
        }
      ],
      section2Data: [
        {
          pic: require('../assets/images/ProductService/section2-1.png'),
          name: '无需下载/订阅',
          content:
            '手机原生短信APP为入口，无需安装/登录，插卡即用，简单开放的用户体系。'
        },
        {
          pic: require('../assets/images/ProductService/section2-2.png'),
          name: '消息即平台',
          content:
            '除支持多媒体内容及卡片消息形式，还可整合直播、VR等应用形成企业超级入口，拥有完整服务闭环。'
        },
        {
          pic: require('../assets/images/ProductService/section2-3.png'),
          name: 'Chatbot快捷接入',
          content:
            '企业用户只需通过舜合5G消息云平台、低成本快速接入5G消息chatbot，即可制作、发送、管理5G消息，无缝对接运营商Maap平台，营销、服务更精准。'
        },
        {
          pic: require('../assets/images/ProductService/section2-4.png'),
          name: '一站式解决方案',
          content:
            '提供可视化5G消息制作工具以及技术服务支持，帮助企业构建起一个弹性、灵活、高效的5G消息平台、满足企业各种业务需求。'
        }
      ],
      section3Data: [
        {
          pic: require('../assets/images/ProductService/section3-1.png'),
          name: '基础能力',
          content:
            '通过完善的chatbot配置、场景配置管理能力，快捷实现5G消息下发。'
        },
        {
          pic: require('../assets/images/ProductService/section3-2.png'),
          name: '开放能力',
          content:
            '支持业界通用技术标准的接口规范，实现与现有业务系统快速对接，提升5G消息场景开发效率。'
        },
        {
          pic: require('../assets/images/ProductService/section3-3.png'),
          name: '服务能力',
          content:
            '提供多种行业5G消息应用模板，可快速实现5G消息应用场景的搭建。'
        }
      ]
    }
  },
  mounted() {
    this.initSwiper()
  },
  methods: {
    initSwiper() {
      new Swiper('.swiper-container', {
        //设置轮播的循环方式
        // loop: true,

        //设置自动播放间隔时间
        // autoplay: 2000,

        // 轮播效果,默认为平滑轮播
        effect: 'slide',

        //分页器
        pagination: '.swiper-pagination',

        //前进后退按钮
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',

        // 用户中断轮播后续播
        autoplayDisableOnInteraction: false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  max-width: 1200px;
  // min-width: 1200px;
}
.header {
  position: relative;
  // height: 600px;
  img {
    width: 100%;
    height: 100%;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    .title {
      font-size: 48px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 67px;
    }
    .sub-title {
      margin-top: 52px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.7;
    }
  }
}
.section-1 {
  padding-bottom: 40px;
  text-align: center;
  .page {
    max-width: 1400px;
    // min-width: 1400px;
  }
  .title {
    margin-top: 101px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
  }
  .content {
    text-align: left;
    margin: 0 auto;
    margin-top: 112px;
    margin-bottom: 50px;
    max-width: 1200px;
    // min-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content-l {
      width: 50%;
      img {
        width: 100%;
      }
    }
    .content-r {
      flex: 1;
      .list {
        padding: 40px 50px;
        .list-title {
          margin-bottom: 27px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
        }
        .list-content {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
        }
      }
    }
  }
  .content2 {
    .list-title {
      text-align: right;
    }

    flex-direction: row-reverse;
  }
}
.section-2 {
  overflow: hidden;
  background: #f9fafb;
  text-align: center;
  padding-bottom: 100px;
  .title {
    margin-top: 94px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.8;
  }
  .content {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    .content-list {
      width: 300px;
      height: 420px;
      position: relative;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .text {
        position: absolute;
        top: 50%;
        padding: 0 25px;
        text-align: left;
        .t {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .st {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
.section-3 {
  overflow: hidden;
  padding-bottom: 100px;
  text-align: center;
  .title {
    margin-top: 111px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .content {
    margin-top: 117px;
    display: flex;
    justify-content: space-between;
    .content-list {
      width: 362px;
      height: 210px;
      position: relative;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .text {
        position: absolute;
        top: 30px;
        padding: 25px;
        text-align: left;
        .t {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .st {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .section-3 .content .content-list .text .t {
    font-size: 18px;
  }
  .section-3 .content .content-list .text .st {
    font-size: 14px;
  }
}
</style>

<!-- 移动端样式 -->
<style lang="scss" scoped>
/* 屏幕小于750px */
@media screen and (max-width: 750px) {
  .page {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    /* min-width: 1000px; */
    margin: 0 auto;
    padding: 0 4vw;
    font-family: 'PingFang SC,Microsoft YaHei';
  }
  .section-1 {
    .page {
      max-width: 100%;
      // min-width: 1400px;
    }
    .title {
      line-height: normal;
      font-size: 76px;
    }
    .sub-title {
      font-size: 40px;
    }
    .content {
      max-width: 100%;
      padding: 0 4vw;
      .content-l {
        width: 50%;
        img {
          width: 100%;
        }
      }
      .content-r {
        flex: 1;
        .list {
          padding: 40px 50px;
          .list-title {
            margin-bottom: 27px;
            font-size: 50px;
          }
          .list-content {
            line-height: normal;
            height: 250px;
            overflow-y: scroll;
            font-size: 40px;
          }
        }
      }
    }
  }
  .section-2 {
    background: #f9fafb;
    text-align: center;
    .title {
      line-height: normal;
      font-size: 76px;
    }
    .sub-title {
      font-size: 40px;
    }
    .content {
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .content-list {
        width: 50%;
        height: 1250px;
        position: relative;
        flex-shrink: 0;
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: auto;
        }
        .text {
          // height: 250px;
          overflow-y: scroll;
          padding: 0 60px 60px 60px;
          .t {
            font-size: 56px;
          }
          .st {
            font-size: 46px;
          }
        }
      }
    }
  }
  .section-3 {
    .title {
      line-height: normal;
      font-size: 76px;
    }
    .sub-title {
      font-size: 40px;
    }
    .content {
      .content-list {
        width: calc(100% / 3);
        height: 410px;
        position: relative;
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        .text {
          padding: 70px 20px 0 40px;
          .t {
            font-size: 46px;
          }
          .st {
            // height: 150px;
            overflow-y: scroll;
            font-size: 30px;
          }
        }
      }
    }
  }
}
</style>
