<template>
  <div class="footer main-bg-color">
    <div class="footer-content page">
      <div class="footer-l">
        <div>联系我们</div>
        <ul>
          <li>联系电话： 400-163-2886（工作日：9:30-17:30）</li>
          <li>商务邮箱： shunhetongda@unioncast.cn</li>
          <li>公司地址： 北京市海淀区中关村南大街寰太大厦大厦901室</li>
        </ul>
      </div>
      <!-- <div class="footer-r"></div> -->
    </div>
    <div class="icp text-center">
      北京舜合通达数字网络科技有限公司@版权所有 京ICP备2021006812号
    </div>
  </div>
</template>
<style lang="scss" scoped>
.footer {
  overflow: hidden;
  padding-bottom: 20px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 200;
  color: #ffffff;
  .footer-content {
    margin-top: 85px;
    display: flex;
    justify-content: space-between;
    .footer-l {
      ul {
        margin-top: 34px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 28px;
      }
    }
    .footer-r {
      width: 140px;
      height: 140px;
      background: #d8d8d8;
      border: 1px solid #979797;
    }
  }
  .icp {
    margin-top: 100px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}
</style>

<!-- 移动端样式 -->
<style lang="scss" scoped>
/* 屏幕小于750px */
@media screen and (max-width: 750px) {
  .footer {
    line-height: normal;
    font-size: 60px;
    .footer-content {
      .footer-l {
        ul {
          line-height: normal;
          font-size: 60px;
        }
      }
    }
    .icp {
      font-size: 60px;
    }
  }
}
</style>
