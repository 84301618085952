<!-- 场景案例 -->
<template>
  <div class="ScenarioCase">
    <div class="banner">
      <img :src="require('../assets/images/ScenarioCase/banner.png')" alt="" />
    </div>

    <div class="section">
      <div class="page">
        <div class="title">场景案例</div>
        <div class="content">
          <div class="content-list" v-for="item in listData" :key="item.name">
            <img :src="item.pic" alt="" />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScenarioCase",
  data () {
    return {
      sectionbgurl: {
        backgroundImage:
          "url(" + require("../assets/images/ScenarioCase/bg.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 388px",
        // minHeight: '388px',
        position: "relative",
        backgroundPosition: "center bottom",
      },
      listData: [
        {
          pic: require("../assets/images/ScenarioCase/1.png"),
          name: "深圳前海蛇口自贸区医院",
        },
        {
          pic: require("../assets/images/ScenarioCase/2.png"),
          name: "数字鼓浪屿",
        },
        {
          pic: require("../assets/images/ScenarioCase/3.png"),
          name: "广州12345服务热线",
        },
        {
          pic: require("../assets/images/ScenarioCase/4.png"),
          name: "慈溪市公安局",
        },
        {
          pic: require("../assets/images/ScenarioCase/5.png"),
          name: "永嘉县文化和广电旅游体育局",
        },
        {
          pic: require("../assets/images/ScenarioCase/6.png"),
          name: "江山气象局",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.ScenarioCase {
  .banner {
    width: 100%;
    img {
      width: 100%;
      // height: 600px;
    }
  }
  .section {
    text-align: center;
    padding-bottom: 100px;
    .title {
      margin-top: 111px;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 50px;
    }
    .sub-title {
      margin-top: 30px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .content-list {
        margin-top: 80px;
        div {
          margin-top: 30px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          // font-weight: 500;
          color: #222222;
        }
        img {
          width: 306px;
          height: 617px;
        }
      }
    }
  }
}
</style>

<!-- 移动端样式 -->
<style lang="scss" scoped>
/* 屏幕小于750px */
@media screen and (max-width: 750px) {
  .ScenarioCase {
    .section {
      .title {
        font-size: 76px;
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .content-list {
          width: 50%;
          margin-top: 80px;
          div {
            margin-top: 30px;
            font-size: 56px;
            font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 500;
            color: #222222;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
</style>
