import { createRouter, createWebHashHistory } from "vue-router";
// import Home from "../views/Home.vue";
import Layout from "../layout/index.vue";

const routes = [
  // 主要页面
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Home.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/ProductService",
        name: "ProductService",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ProductService.vue"),
        meta: {
          title: "产品服务",
        },
      },
      {
        path: "/Solution",
        name: "Solution",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Solution.vue"),
        meta: {
          title: "解决方案",
        },
      },
      {
        path: "/ScenarioCase",
        name: "ScenarioCase",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ScenarioCase.vue"),
        meta: {
          title: "场景案例",
        },
      },
      {
        path: "/shMsg",
        name: "shMsg",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/shMsg.vue"),
        meta: {
          title: "解决方案",
        },
      },
      {
        path: "/about",
        name: "about",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
        meta: {
          title: "关于我们",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
    meta: {
      title: "登录",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
