import { createStore } from "vuex";

export default createStore({
  state: {
    childSelected: "",
  },
  mutations: {
    SETCHILDSELECTED (state, info) {
      console.log(info);
      state.childSelected = info;
    },
  },
  actions: {},
  modules: {},
});
