<template>
  <div class="swiper">
    <!-- swiper -->
    <div class="swiper-container">
      <!-- 需要进行轮播的部分 -->
      <div class="swiper-wrapper">
        <!-- 每个节点 -->
        <div class="swiper-slide" v-for="item in bannerData" :key="item.name">
          <img :src="item.pic" alt="图片1" />
        </div>
      </div>
      <!--如果需要使用分页器-->
      <div class="swiper-pagination swiper-pagination-white"></div>

      <!-- 如果需要使用前进后退按钮 -->
      <div class="swiper-button-prev swiper-button-white"></div>
      <div class="swiper-button-next swiper-button-white"></div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import '../../node_modules/swiper/dist/css/swiper.min.css'
import '../../node_modules/swiper/dist/js/swiper.min.js'
export default {
  data() {
    return {
      bannerData: [
        {
          pic: require('../assets/images/home/banner1.png'),
          name: 'cmmi认证企业'
        },
        {
          pic: require('../assets/images/home/banner2.png'),
          name: 'IOS27001认证'
        },
        {
          pic: require('../assets/images/home/banner3.png'),
          name: '高薪技术产业'
        }
      ]
    }
  },
  mounted() {
    this.initSwiper()
  },
  methods: {
    initSwiper() {
      new Swiper('.swiper-container', {
        //设置轮播的循环方式
        // loop: true,

        //设置自动播放间隔时间
        // autoplay: 2000,

        // 轮播效果,默认为平滑轮播
        effect: 'slide',

        //分页器
        pagination: '.swiper-pagination',

        //前进后退按钮
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',

        // 用户中断轮播后续播
        autoplayDisableOnInteraction: false
      })
    }
  }
}
</script>
<style scoped>
.swiper-container {
  width: 100%;
  /* height: 800px; */
}

.swiper-slide img {
  width: 100%;
  /* height: 800px; */
  object-fit: cover;
}
</style>
