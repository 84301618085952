<template>
  <div class="home">
    <!-- 轮播图 -->
    <Swiper></Swiper>
    <section class="section-1" :style="section1bgurl">
      <div class="page">
        <div class="icon">
          <img :src="require('../assets/images/home/bottom.png')" alt="" />
        </div>
        <div class="title wow fadeInUp">可信/便捷/交互/智能</div>
        <div class="sub-title wow fadeInUp">
          5G行业消息将端口号升级为Chatbot（聊天机器人） ，为企业和个人用户建立直接联系。<br />用户可在Chatbot消息窗口中完成搜索、交互等一站式体验，实现新型定制化人机智能交互。
        </div>
        <div class="content">
          <div class="content-l wow fadeInLeft">
            <div class="list">
              <div class="text">品牌认证</div>
              <img :src="require('../assets/images/home/section1-1.png')" alt="" />
            </div>
            <div class="list">
              <div class="text">富媒体搭载</div>
              <img :src="require('../assets/images/home/section1-2.png')" alt="" />
            </div>
            <div class="list">
              <div class="text">多服务入口</div>
              <img :src="require('../assets/images/home/section1-3.png')" alt="" />
            </div>
          </div>
          <div class="content-r wow fadeInRight">
            <div class="list">
              <img :src="require('../assets/images/home/section1-4.png')" alt="" />
              <div class="text">AI对话</div>
            </div>
            <div class="list">
              <img :src="require('../assets/images/home/section1-5.png')" alt="" />
              <div class="text">强交互能力</div>
            </div>
            <div class="list">
              <img :src="require('../assets/images/home/section1-6.png')" alt="" />
              <div class="text">分级菜单</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-2">
      <div class="page">
        <div class="title wow fadeInUp">多行业模板一键应用/轻松创建5G消息及chatbot交互</div>
        <div class="sub-title wow fadeInUp">党政|民生|金融|文旅|消防|电商|智慧城市|数智乡村…</div>
        <div class="content wow fadeInUp">
          <div class="content-1">
            <div></div>
            <img :src="require('../assets/images/home/section2-1.png')" alt="" />
            <img :src="require('../assets/images/home/section2-2.png')" alt="" />
            <img :src="require('../assets/images/home/section2-3.png')" alt="" />
            <img :src="require('../assets/images/home/section2-4.png')" alt="" />
            <img :src="require('../assets/images/home/section2-5.png')" alt="" />
            <div></div>
          </div>
          <div class="content-2">
            <img :src="require('../assets/images/home/section2-6.png')" alt="" />
            <img :src="require('../assets/images/home/section2-7.png')" alt="" />
            <img :src="require('../assets/images/home/section2-8.png')" alt="" />
            <img :src="require('../assets/images/home/section2-9.png')" alt="" />
            <img :src="require('../assets/images/home/section2-10.png')" alt="" />
            <img :src="require('../assets/images/home/section2-11.png')" alt="" />
          </div>
        </div>
      </div>
      <div class="h20"></div>
      <div class="h20"></div>
      <div class="h20"></div>
    </section>
    <section class="section-3">
      <div class="page">
        <div class="title wow fadeInUp">5G消息云平台使用流程</div>
        <div class="sub-title wow fadeInUp">我们用先进的技术与服务助力您开启智慧5G消息之旅</div>
        <div class="content">
          <div class="content-list wow fadeInLeft0">
            <img :src="require('../assets/images/home/section3-bg1.png')" alt="" />
            <div class="list-footer">
              <div class="footer-title">免费注册</div>
              <div class="footer-text">在平台官网进行免费注册，提交申请成为平台用户。</div>
            </div>
          </div>
          <div class="content-icon wow fadeInLeft1">
            <img :src="require('../assets/images/home/right.png')" alt="" />
          </div>
          <div class="content-list wow fadeInLeft2">
            <img :src="require('../assets/images/home/section3-bg2.png')" alt="" />
            <div class="list-footer">
              <div class="footer-title">企业认证</div>
              <div class="footer-text">按照操作指引在线提交企业资质信息完成审核。</div>
            </div>
          </div>
          <div class="content-icon wow fadeInLeft3">
            <img :src="require('../assets/images/home/right.png')" alt="" />
          </div>
          <div class="content-list wow fadeInLeft4">
            <img :src="require('../assets/images/home/section3-bg3.png')" alt="" />
            <div class="list-footer">
              <div class="footer-title">开通使用</div>
              <div class="footer-text">信息审核通过后即可开启5G消息应用之旅了。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="h20"></div>
      <div class="h20"></div>
      <div class="h20"></div>
      <div class="h20"></div>
      <div class="h20"></div>
      <div class="h10"></div>
    </section>
    <section class="section-4">
      <div class="page">
        <div class="title wow fadeInUp">安全与认证</div>
        <div class="sub-title wow fadeInUp">为开发者们提供具备安全保障的可靠服务</div>
        <div class="content wow fadeInUp">
          <div v-for="item in aqrzData" :key="item.name" class="content-list">
            <img :src="item.pic" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="section-5" :style="section5bgurl">
      <div class="page">
        <div class="title wow fadeInUp">资讯中心</div>
        <div class="sub-title wow fadeInUp">带您解锁5G消息新局面</div>
        <div class="news-box">
          <div class="news-l wow fadeInLeft">
            <div class="news-img">
              <img :src="require('../assets/images/home/section5.jpg')" alt="" />
              <div class="news-title">舜合通达5G消息云平台荣获“5G消息平台功能卓越级证书”</div>
            </div>
            <div class="news-list">
              <div class="news-title">5G消息与微信公众号的区别是什么？</div>
              <div class="news-content">
                手机号即个人身份ID，免除了微信、App的注册登录过程，避免了账号信息被盗用。SIM卡开通后即已完成实名认证，解决身份认证问题，安全性提升。
              </div>
              <!-- <div class="news-footer">
                <div>2020/04/10</div>
                <div>更多</div>
              </div> -->
            </div>
          </div>
          <div class="news-r wow fadeInRight">
            <div class="news-list">
              <div class="news-title">什么是5G消息？</div>
              <div class="news-content">
                5G消息是一种基于GSMA国际通信标准（RCS）的富媒体消息应用，提供直达、便捷、大容量、内容丰富的富媒体信息服务，支持用户交互，为客户带来全方位的内容服务和沟通价值。
              </div>
              <!-- <div class="news-footer">
                <div>2022/05/06</div>
                <div>更多</div>
              </div> -->
            </div>
            <div class="news-list">
              <div class="news-title">5G消息的应用场景有哪些？</div>
              <div class="news-content">
                5G消息丰富的应用场景，构建全新的社交和信息服务入口。业务分为两大类：一类是个人用户与个人用户之间交互的消息，另一类是行业客户与个人用户之间交互的消息，广泛应用于宣传通知、业务办理、学习办公、票务预订、活动营销等。
              </div>
              <!-- <div class="news-footer">
                <div>2020/08/21</div>
                <div>更多</div>
              </div> -->
            </div>
            <div class="news-list">
              <div class="news-title">5G消息有哪些优势？</div>
              <div class="news-content">
                5G消息具有功能丰富、安全可信、社交入口等优势。用户无需下载安装、无需关注即可使用，同时可向个人用户展示客户名称、LOGO并提供固定菜单服务，为客户创造更多价值。
              </div>
              <!-- <div class="news-footer">
                <div>2021/10/09</div>
                <div>更多</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Swiper from "../components/swiper.vue";
import { WOW } from "wowjs";
export default {
  name: "Home",
  components: { Swiper },
  data () {
    return {
      section1bgurl: {
        backgroundImage:
          "url(" + require("../assets/images/home/section1-bg1.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        backgroundPosition: "center center",
      },
      section3bgurl: {
        backgroundImage:
          "url(" + require("../assets/images/home/section3-bg1.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
        position: "relative",
        backgroundPosition: "center center",
      },
      section5bgurl: {
        backgroundImage:
          "url(" + require("../assets/images/home/section5-bg.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100%",
        position: "relative",
        backgroundPosition: "center center",
      },
      aqrzData: [
        {
          pic: require("../assets/images/home/section4-1.png"),
          name: "cmmi认证企业",
        },
        {
          pic: require("../assets/images/home/section4-2.png"),
          name: "IOS27001认证",
        },
        {
          pic: require("../assets/images/home/section4-3.png"),
          name: "高薪技术产业",
        },
        {
          pic: require("../assets/images/home/section4-4.png"),
          name: "IOS9001认证",
        },
      ],
    };
  },
  mounted () {
    // wow初始化
    this.$nextTick(() => {
      const wow = new WOW({
        boxClass: "wow", // default
        animateClass: "animated", // default
        offset: 150, // default
        mobile: true, // default
        live: false,
        // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
        callback: function (box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">");
        },
      });
      wow.init();
    });
  },
};
</script>
<style lang="scss" scoped>
.h20 {
  height: 20px;
}
.h10 {
  height: 10px;
}
.section-1 {
  padding-bottom: 20vh;
  text-align: center;
  .icon {
    margin-top: 30px;
    img {
      width: 2vw;
      height: 2vw;
    }
  }
  .title {
    margin-top: 40px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 14px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 36px;
  }
  .content {
    margin-top: 140px;
    display: flex;
    flex: auto;
    justify-content: space-between;
    .content-l {
      .list {
        display: flex;
        .text {
          flex: 1;
          text-align: right;
        }
        img {
          margin-left: 50px;
        }
      }
    }
    .content-r {
      .list {
        display: flex;
        .text {
          flex: 1;
          text-align: left;
        }
        img {
          margin-right: 50px;
        }
      }
    }
    .list {
      display: flex;
      align-items: center;
      margin-bottom: 76px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 500;
      color: #222222;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}
.section-2 {
  // overflow: hidden;
  background: #173dba;
  // height: 820px;
  text-align: center;
  .page {
    max-width: 1400px;
  }
  .title {
    padding-top: 100px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.8;
  }
  .content {
    margin-top: 100px;
    .content-1 {
      display: flex;
      justify-content: center;
      div {
        width: calc(100% / 12);
      }
      img {
        width: calc(100% / 6);
        flex-shrink: 1;
        // height: 220px;
      }
    }
    .content-2 {
      display: flex;
      justify-content: center;
      img {
        width: calc(100% / 6);
        flex-shrink: 1;
        // height: 220px;
      }
    }
  }
}
.section-3 {
  // overflow: hidden;
  // height: 700px;
  text-align: center;
  .page {
    min-width: 100%;
    text-align: center;
  }
  .title {
    margin-top: 100px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .content {
    margin-top: 82px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    .content-icon {
      img {
        width: 32px;
        height: 30px;
      }
    }
    .content-list {
      max-width: 348px;
      max-height: 308px;
      margin: 0 32px;
      position: relative;
      // flex-shrink: 0;
      img {
        // position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .list-footer {
        position: absolute;
        top: 58%;
        width: 100%;
        text-align: center;
        .footer-title {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .footer-text {
          margin-top: 15px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 22px;
          padding: 0 25px;
        }
      }
    }
  }
}

.section-4 {
  // overflow: hidden;
  background: #173dba;
  height: 600px;
  text-align: center;
  .page {
    max-width: 1288px;
    text-align: center;
  }
  .title {
    padding-top: 102px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.8;
  }
  .content {
    margin-top: 71px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    justify-content: center;
    .content-list {
      // margin: 0 44px;
      width: calc(100% / 4);
      padding: 0 44px;
      box-sizing: border-box;
      img {
        width: 100%;
        // height: 198px;
        margin-bottom: 61px;
      }
    }
  }
}
.section-5 {
  overflow: hidden;
  padding-bottom: 40px;
  text-align: center;
  background: #ffffff;
  .page {
    max-width: 1200px;
  }

  .title {
    margin-top: 47px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 50px;
  }
  .sub-title {
    margin-top: 10px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .news-box {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    .news-l {
      flex: 1;
      margin-right: 20px;
      .news-img {
        box-sizing: border-box;
        height: 340px;
        padding: 10px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        border-radius: 6px;
        margin-bottom: 20px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .news-title {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
          line-height: 46px;
          color: #fff;
          font-size: 18px;
        }
      }
    }
    .news-r {
      flex: 1;
    }
    .news-list {
      text-align: left;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      border-radius: 6px;
      margin-bottom: 20px;
      .news-title {
        margin: 20px 0 0 20px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 25px;
      }
      .news-content {
        padding: 12px 20px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
      .news-footer {
        font-size: 12px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #eeeeee;
        color: #999999;
        line-height: 39px;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .section-3 .content .content-list .list-footer .footer-title {
    font-size: 18px;
  }
  .section-3 .content .content-list .list-footer .footer-text {
    font-size: 14px;
    margin-top: 8px;
  }
}

.fadeInUp {
  animation: fadeInUp 0.5s linear 1;
}
.fadeInLeft {
  animation: fadeInLeft 0.5s linear 1;
}
.fadeInLeft0 {
  animation: fadeInLeft 0.2s linear 1;
}
.fadeInLeft1 {
  animation: fadeInLeft 0.4s linear 1;
}
.fadeInLeft2 {
  animation: fadeInLeft 0.6s linear 1;
}
.fadeInLeft3 {
  animation: fadeInLeft 0.8s linear 1;
}
.fadeInLeft4 {
  animation: fadeInLeft 1s linear 1;
}
.fadeInRight {
  animation: fadeInRight 0.5s linear 1;
}
</style>

<!-- 移动端样式 -->
<style lang="scss" scoped>
/* 屏幕小于750px */
@media screen and (max-width: 750px) {
  .page {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    /* min-width: 1000px; */
    margin: 0 auto;
    padding: 0 4vw;
    font-family: 'PingFang SC,Microsoft YaHei';
  }
  .section-1 {
    .sub-title {
      line-height: normal;
    }
  }
  .section-3 {
    text-align: center;
    .content {
      .content-list {
        max-width: 500px;
        max-height: 408px;

        .list-footer {
          height: 204px;
          overflow-y: scroll;
          .footer-text {
            text-align: left;
            line-height: normal;
          }
        }
      }
    }
  }

  .section-5 {
    .page {
      max-width: 100%;
    }
    .news-box {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      .news-l {
        flex: 1;
        margin-right: 20px;
        .news-img {
          box-sizing: border-box;
          height: 340px;
          padding: 10px;
          background: #ffffff;
          border: 1px solid #f2f2f2;
          border-radius: 6px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
          .news-title {
            line-height: 180px;
            font-size: 58px;
          }
        }
      }
      .news-r {
        flex: 1;
      }
      .news-list {
        text-align: left;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        border-radius: 6px;
        margin-bottom: 20px;
        .news-title {
          margin: 20px 0 0 20px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: normal;
        }
        .news-content {
          padding: 12px 20px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: normal;
        }
        .news-footer {
          font-size: 12px;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #eeeeee;
          color: #999999;
          line-height: normal;
        }
      }
    }
  }

  .section-1 {
    .icon {
      margin-top: 0;
      img {
        width: 3vw;
        height: 3vw;
      }
    }
    .title {
      margin-top: 40px;
      font-size: 76px;
      line-height: 50px;
    }
    .sub-title {
      margin-top: 34px;
      font-size: 40px;
      line-height: 66px;
    }
    .content {
      margin-top: 340px;
      box-sizing: border-box;
      padding-top: 300px;
      .content-l {
        .list {
          .text {
            flex: 1;
            text-align: right;
            font-size: 66px;
          }
        }
      }
      .content-r {
        .list {
          display: flex;
          .text {
            flex: 1;
            text-align: left;
            font-size: 66px;
          }
          img {
            margin-right: 50px;
          }
        }
      }
      img {
        width: 80px;
        height: auto;
      }
    }
  }
  .section-2 {
    // overflow: hidden;
    background: #173dba;
    // height: 820px;
    text-align: center;
    .page {
      max-width: 100%;
    }
    .title {
      font-size: 76px;
      line-height: 100px;
    }
    .sub-title {
      margin-top: 40px;
      font-size: 40px;
    }
  }
  .section-3 {
    .page {
      min-width: 100%;
      text-align: center;
    }
    .title {
      font-size: 76px;
    }
    .sub-title {
      margin-top: 40px;
      font-size: 40px;
    }
    .content {
      .content-list {
        .list-footer {
          .footer-title {
            font-size: 48px;
          }
          .footer-text {
            margin-top: 20px;
            font-size: 36px;
            line-height: 50px;
          }
        }
      }
    }
  }

  .section-4 {
    height: 700px;
    .page {
      max-width: 100%;
      text-align: center;
    }
    .title {
      font-size: 76px;
    }
    .sub-title {
      margin-top: 40px;
      font-size: 40px;
    }
  }
  .section-5 {
    overflow: hidden;
    padding-bottom: 40px;
    text-align: center;
    background: #ffffff;
    .page {
      max-width: 100%;
    }

    .title {
      margin-top: 100px;
      font-size: 76px;
    }
    .sub-title {
      margin-top: 40px;
      font-size: 40px;
    }
    .news-box {
      margin-top: 50px;
      display: block;
      .news-l {
        .news-img {
          height: auto;
        }
      }
      .news-list {
        text-align: left;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        border-radius: 6px;
        margin-bottom: 20px;
        padding: 40px;
        .news-title {
          font-size: 58px;
        }
        .news-content {
          padding: 40px 0;
          font-size: 48px;
          line-height: 80px;
        }
        .news-footer {
          font-size: 44px;
          padding: 40px 20px 20px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #eeeeee;
          color: #999999;
          line-height: 39px;
        }
      }
    }
  }
}
</style>
