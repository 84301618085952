<!-- 解决方案 -->
<template>
  <div class="Solution">
    <div class="header">
      <img :src="require('../assets/images/Solution/banner.png')" alt="" />
      <div class="content">
        <div class="title">{{ listData[activeIndex].title }}</div>
        <div class="sub-title">
          {{ listData[activeIndex].subTitle }}
        </div>
      </div>
    </div>
    <div class="flex-box">
      <!-- tab切换 -->
      <div class="tab-box">
        <!-- <el-radio-group v-model="activeIndex">
        <el-radio-button v-for="item in listData" :label="item.index" :key="item.index">{{ item.name }}</el-radio-button>
      </el-radio-group> -->
        <div class="tab-box-content" ref="SideslipBox">
          <div
            v-for="(item, tabIndex) in listData"
            :label="item.index"
            :key="item.index"
            class="tab-box-content-list"
            :class="tabIndex === activeIndex ? 'tab-box-active' : ''"
            @click="tabClick(tabIndex)"
            :ref="'SideslipBox' + tabIndex"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <section class="section">
        <!-- 行业痛点 -->
        <div class="title">行业痛点</div>
        <div class="IndustryPainPoints">
          <img :src="listData[activeIndex].IndustryPainPoints.icon" alt="" />
          <div class="content">
            <div
              class="list-item"
              v-for="item in listData[activeIndex].IndustryPainPoints.list"
              :key="item.title"
            >
              <img :src="item.icon" alt="" />
              <div class="list-box">
                <div class="list-title">{{ item.title }}</div>
                <div class="list-text">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 解决方案 -->
        <div class="title">解决方案</div>
        <div class="Solutions">
          <div
            class="list-item"
            v-for="item in listData[activeIndex].Solutions"
            :key="item.title"
          >
            <img :src="item.icon" alt="" />
            <div class="list-box">
              <div class="list-title">{{ item.title }}</div>
              <div class="list-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Solution',
  data() {
    return {
      activeIndex: 0,
      listData: [
        {
          index: 0,
          name: '教育领域',
          title: '智慧校园5G消息解决方案',
          subTitle: '5G时代的数字化教育服务新体验',
          IndustryPainPoints: {
            icon: require('../assets/images/Solution/jy.png'),
            list: [
              {
                icon: require('../assets/images/Solution/icon1-1.png'),
                title: '缺乏完整业务闭环',
                text: '从入园办理、缴费到课程内容等，缺乏有效闭环。'
              },
              {
                icon: require('../assets/images/Solution/icon1-2.png'),
                title: '信息触达率',
                text: 'APP安装率和公众号等其他方式关注度低，服务信息无法直观有效触达用户。'
              }
            ]
          },
          Solutions: [
            {
              icon: require('../assets/images/Solution/icon1-3.png'),
              title: '智慧招生：',
              text: '针对学校招生需求，5G行业消息中台数据融合匹配目标用户，一键推送招生信息至目标用户，多样化展示学校环境，提升学校招生效率。'
            },
            {
              icon: require('../assets/images/Solution/icon1-4.png'),
              title: '新生录取：',
              text: '录取通知链接丰富的新生服务场景，一体化解决新生入学各需求：入学须知、校园文化展示、入学交通指引，让新生入学变得简单便捷。'
            },
            {
              icon: require('../assets/images/Solution/icon1-5.png'),
              title: '综合服务大厅：',
              text: '针对各类校园服务，关键节点触发实现校园服务指尖办、智慧办。网络续费、校园卡充值、图书馆座位预订、开学通知等都能以5G行业消息节点触发，实现校园服务智慧化管理。'
            },
            {
              icon: require('../assets/images/Solution/icon1-6.png'),
              title: '教务在线：',
              text: '通过5G行业消息将教务系统掌上化，覆盖学生、教师、游客等多方角色服务，一键直达，快捷办理；配套反馈体系提升教务服务水平。'
            },
            {
              icon: require('../assets/images/Solution/icon1-7.png'),
              title: '智慧图书馆：',
              text: '5G行业消息下的智慧图书馆，基于短信原生窗口打造图书自助借还、图书馆座位预约、文献快速查询、勤工俭学等多种服务场景，实现校园图书馆的智能化管理。'
            }
          ]
        },
        {
          index: 1,
          name: '金融领域',
          title: '金融银行领域5G消息解决方案',
          subTitle: '打造金融行业消息服务新生态',
          IndustryPainPoints: {
            icon: require('../assets/images/Solution/jr.png'),
            list: [
              {
                icon: require('../assets/images/Solution/icon2-1.png'),
                title: '行业客户满意度出发',
                text: '缺乏互动，体验缺乏人'
              },
              {
                icon: require('../assets/images/Solution/icon2-2.png'),
                title: '企业经营活动效率出发',
                text: '场景单一，流量难以二次分发'
              },
              {
                icon: require('../assets/images/Solution/icon2-3.png'),
                title: '企业营收出发',
                text: '老客流失唤醒成本高，新客拓展成本高。'
              }
            ]
          },
          Solutions: [
            {
              icon: require('../assets/images/Solution/icon2-4.png'),
              title: '智能客服：',
              text: '对接行内客服系统，用户在5G消息内可免登录直达银行的智能客服系统，实现快速咨询服务。'
            },
            {
              icon: require('../assets/images/Solution/icon2-5.png'),
              title: '账户变动通知：',
              text: '基于5G消息富媒体形态的特质，以卡片形式向用户推送储蓄账户及信用卡账户变动通知，字段分行展示，便于阅读，并支持富媒体营销信息贴尾。 '
            },
            {
              icon: require('../assets/images/Solution/icon2-6.png'),
              title: '信用卡还款：',
              text: '通过5G消息服务，选择还款账户、确认还款金额，只需3步，即可快速完成信用卡一键还款。'
            },
            {
              icon: require('../assets/images/Solution/icon2-7.png'),
              title: '网点查询与取号：',
              text: '客户通过5G消息发送位置信息，可查询所发位置附近网点，支持客户查询网点排号情况并快速完成取号。'
            },
            {
              icon: require('../assets/images/Solution/icon1-7.png'),
              title: '信息查询：',
              text: '储蓄账户支持通过点击菜单或自然语言指令查询储蓄账户余额、明细、子账户等，系统以卡片形式展示查询结果。信用卡账单支持通过点击菜单或自然语言指令查询信用卡账单，系统以卡片形式展示查询结果。'
            },
            {
              icon: require('../assets/images/Solution/icon1-8.png'),
              title: '交费通知场景：',
              text: '通过5G消息向客户推送党费待交信息、信用卡账单、水电煤缴费、话费账单等，实现一键还款和缴费，无需冗余身份验证；无需跳转APP和页面，展示直观、操作便捷，客户操作不中断，实现交易闭环。'
            }
          ]
        },
        {
          index: 2,
          name: '电力领域',
          title: '电力领域5G消息解决方案',
          subTitle: '5G时代的数智化服务新体验',
          IndustryPainPoints: {
            icon: require('../assets/images/Solution/dl.png'),
            list: [
              {
                icon: require('../assets/images/Solution/icon3-1.png'),
                title: '费用提醒、催缴通知下发渠道受限，消息接收成功率低',
                text: '用户忘记缴纳水电费或收到账单无法立即缴纳费用，造成人员通知成本投入高'
              },
              {
                icon: require('../assets/images/Solution/icon3-2.png'),
                title: '消息类型单一，对于用户需求无法形成闭环服务',
                text: '账单信息单一，无法对用户使用情况进行分析，同时，无法展现网点信息。'
              }
            ]
          },
          Solutions: [
            {
              icon: require('../assets/images/Solution/icon3-3.png'),
              title: '户号绑定：',
              text: '与业务系统对接，完成户号绑定，增加用户指引。'
            },
            {
              icon: require('../assets/images/Solution/icon3-4.png'),
              title: '消息通知：',
              text: '停电信息，实时更新抢修进度、预计来电时间等，可视化业务链接。 '
            },
            {
              icon: require('../assets/images/Solution/icon3-5.png'),
              title: '调查问卷：',
              text: '推送问卷调查，记录客户选择结果，形成调查报告。'
            },
            {
              icon: require('../assets/images/Solution/icon3-6.png'),
              title: '一键上传：',
              text: '窃电一键举报，现场图片&视频一键发送，简化举报流程。'
            },
            {
              icon: require('../assets/images/Solution/icon3-7.png'),
              title: '办电申请：',
              text: '提供业扩新装、增容及变更用电线上申请服务。'
            },
            {
              icon: require('../assets/images/Solution/icon3-8.png'),
              title: '位置服务：',
              text: '故障报修时可进行定位、地址及门牌号填写上报。'
            }
          ]
        },
        {
          index: 3,
          name: '智慧医疗',
          title: '智慧医疗5G消息解决方案',
          subTitle: '5G时代的数智化服务新体验',
          IndustryPainPoints: {
            icon: require('../assets/images/Solution/yl.png'),
            list: [
              {
                icon: require('../assets/images/Solution/icon4-1.png'),
                title: '医疗资源分布不均衡',
                text: '就医院而言,医疗资源分布不均衡、运行效率不高体系建设不完善的问题越发突出'
              },
              {
                icon: require('../assets/images/Solution/icon4-2.png'),
                title: '看病难、看病贵',
                text: '就用户而言,看病难、看病贵和用户体验差是行业突出存在的问题'
              }
            ]
          },
          Solutions: [
            {
              icon: require('../assets/images/Solution/icon4-3.png'),
              title: '预约挂号：',
              text: '用户可通过5G消息菜单或对话进入预约挂号页面，挂号选医生、确认信息，一个窗口完成就医服务，服务体验有效提升。'
            },
            {
              icon: require('../assets/images/Solution/icon4-4.png'),
              title: '排队候诊：',
              text: '通过5G消息查看排队候诊时间，提醒当前候诊人数及预计候诊时间，就诊排队序号手机实时查询，用户可合理安排候诊时间，就诊时间获得保障。'
            },
            {
              icon: require('../assets/images/Solution/icon4-5.png'),
              title: '院内导航：',
              text: '院内“导航神器”院内导航系统采用高精度、高稳定度蓝牙定位技术，因此仅需打开手机蓝牙即可，在医院不迷路，通过5G消息就诊导航直达目的科室。'
            },
            {
              icon: require('../assets/images/Solution/icon4-6.png'),
              title: '在线问诊：',
              text: '5G消息为患者提供便利的在线问诊服务，患者免去医院现场挂号排队长、看病难、体验差等问题，帮助居民足不出户看病问诊。'
            },
            {
              icon: require('../assets/images/Solution/icon4-7.png'),
              title: '报告查询：',
              text: '基于5G技术的智慧医疗平台，医院可将带有电子公章的检验报告以图片方式主动、及时、安全、准确地送达患者，基于5G技术在智慧医疗场景创新应用。'
            },
            {
              icon: require('../assets/images/Solution/icon4-8.png'),
              title: '科普宣教：',
              text: '通过“5G消息” 普及健康生活方式，提升全民健康素养。优质医疗资源，用视频直播、漫画展现、音频等多种形式，全方位普及健康知识。'
            }
          ]
        },
        {
          index: 4,
          name: '电商领域',
          title: '电商领域5G消息解决方案',
          subTitle: '5G时代的数智化服务新体验',
          IndustryPainPoints: {
            icon: require('../assets/images/Solution/ds.png'),
            list: [
              {
                icon: require('../assets/images/Solution/icon5-1.png'),
                title: '获客成本高、客户忠诚度低,难以保证投资回报',
                text: '获取用户流量成本太高，转化率得不到保障，缺乏强触达且能够丰富展现产品特性的触点。'
              },
              {
                icon: require('../assets/images/Solution/icon5-2.png'),
                title: '品牌化渗透较弱，人员维护成本高',
                text: '越来越多的商家开始将客户从公域平台引流到私域，希望加强品牌与客户的黏性，最大化客户生命周期价值。'
              }
            ]
          },
          Solutions: [
            {
              icon: require('../assets/images/Solution/icon5-3.png'),
              title: '店铺介绍：',
              text: '通过丰富的5G消息模板下发，提升品牌在终端用户的渗透度，有效加强品牌宣传。'
            },
            {
              icon: require('../assets/images/Solution/icon5-4.png'),
              title: '在线下单：',
              text: '5G消息逐渐转变为一个有效的销售渠道，通过接入增值服务的支付能力，在商品强触达用户的同时，引导用户下单付费，完成转化。 '
            },
            {
              icon: require('../assets/images/Solution/icon5-5.png'),
              title: '产品系列：',
              text: '突出产品定位差异化，打造标杆产品。'
            },
            {
              icon: require('../assets/images/Solution/icon5-6.png'),
              title: '优惠活动：',
              text: '5G消息赋能活动营销新渠道，多条业务线联动，拉升活动效果，从而提升营销转化。'
            },
            {
              icon: require('../assets/images/Solution/icon5-7.png'),
              title: '会员中心：',
              text: '提升用户留存和转化，从5G消息强触达、用户转化、复购多个角度持续增长，是用户留存的有效功能点。'
            }
          ]
        },
        {
          index: 5,
          name: '数智乡村',
          title: '数智乡村5G消息解决方案',
          subTitle: '5G时代的数智化服务新体验',
          IndustryPainPoints: {
            icon: require('../assets/images/Solution/xc.png'),
            list: [
              {
                icon: require('../assets/images/Solution/icon6-1.png'),
                title: '资源要素尚不完整，整合平台有待优化',
                text: '数智乡村外力不足，缺乏资源整合的支撑平台， 乡村振兴所需的众多资源管理混乱，数据孤岛、系统割裂等问题普遍存在，急需建立一个统筹各方资源的整合平台。'
              },
              {
                icon: require('../assets/images/Solution/icon6-2.png'),
                title: '信息互通体系不够健全',
                text: '村民和政府之间缺少有效的信息互通，政府下发的涉农政策无法有效触达到村民侧，村民也无法享受到福利。村民与政府之间的信息无法形成有效闭环。'
              }
            ]
          },
          Solutions: [
            {
              icon: require('../assets/images/Solution/icon6-3.png'),
              title: '政策板块：',
              text: '打造以农村用户为中心的三农服务新生态，实现政策通知信 息高效精准发布，集成政务、应急、金融、民生、安全等应 用内容，助力乡村信息化建设。'
            },
            {
              icon: require('../assets/images/Solution/icon6-4.png'),
              title: '应急预警：',
              text: '基于5G消息富媒体形态的特质，以卡片形式向用户推送储蓄账户及信用卡账户变动通知，字段分行展示，便于阅读，并支持富媒体营销信息贴尾。 '
            },
            {
              icon: require('../assets/images/Solution/icon6-5.png'),
              title: '惠农板块：',
              text: '通过5G消息推送乡村旅游特色、助农产品宣传，鼓励电 子商务平台、网上课堂等生产型平台向农村地区延伸。'
            },
            {
              icon: require('../assets/images/Solution/icon6-6.png'),
              title: '调查问卷：',
              text: '开展乡村振兴战略实施情况民 意调查，通过5G消息调查县（市）、乡镇、村，问卷调查可 包含农村干群对乡村振兴战略的认同度，农村变化等。'
            },
            {
              icon: require('../assets/images/Solution/icon6-7.png'),
              title: '健康板块：',
              text: '用户可通过5G消息进行交互，从而引导用户实现与医院的强粘性，提升服务感知。实现智能机器人对话、智能导诊，患者就诊指南的高效服务功能。'
            },
            {
              icon: require('../assets/images/Solution/icon6-8.png'),
              title: '疫情防护：',
              text: '将疫情防护科普+核酸预约等功能服务融合于“数智乡村看湖北”，让村民从了解最新的疫情动态。'
            }
          ]
        },

        {
          index: 6,
          name: '文旅领域',
          title: '文旅领域5G消息解决方案',
          subTitle: '5G时代的数智化服务新体验',
          IndustryPainPoints: {
            icon: require('../assets/images/Solution/ly.png'),
            list: [
              {
                icon: require('../assets/images/Solution/icon7-1.png'),
                title: '场景与游客的互动性弱，文化资源内涵挖掘层次不够深入',
                text: '有些文化旅游项目开发仅仅停留在游览、观赏的基础层面，游客的参与性和互动性相对缺乏。'
              },
              {
                icon: require('../assets/images/Solution/icon7-2.png'),
                title: '缺乏有效的推广模式，过度依赖三方平台，营收突破难',
                text: '投入与产出不成正比'
              }
            ]
          },
          Solutions: [
            {
              icon: require('../assets/images/Solution/icon7-3.png'),
              title: '门票预订：',
              text: '无需借助第三方APP注册、登录进行购票。通过5G消息触达，针对当地景区提供专业的购票服务，方便快捷。'
            },
            {
              icon: require('../assets/images/Solution/icon7-4.png'),
              title: '景点介绍：',
              text: '通过5G消息多样化的展现形式，更直接、更全面的介绍景点信息，有助于提升游客对景点的认知度。'
            },
            {
              icon: require('../assets/images/Solution/icon7-5.png'),
              title: '智慧地图：',
              text: '根据地图景点游玩，景区设施尽收眼底，降低游客游玩时间成本。'
            },
            {
              icon: require('../assets/images/Solution/icon7-6.png'),
              title: '在线答疑：',
              text: '无需向工作人员咨询相关问题，手机一键操作即可对景区了如指掌，节省游客时间。'
            },
            {
              icon: require('../assets/images/Solution/icon7-7.png'),
              title: '景区公告：',
              text: '通过5G消息触达游客，游客提前了解优惠政策、出行时间等事项，提升好感度。'
            },
            {
              icon: require('../assets/images/Solution/icon7-8.png'),
              title: '线上购买文旅产品：',
              text: '通过5G消息一体化的景区智能服务，在游客认知度高的情境下，加强交易转化，提升营销收入。'
            }
          ]
        },
        {
          index: 7,
          name: '消防领域',
          title: '消防领域5G消息解决方案',
          subTitle: '5G时代的数智化服务新体验',
          IndustryPainPoints: {
            icon: require('../assets/images/Solution/xf.png'),
            list: [
              {
                icon: require('../assets/images/Solution/icon8-1.png'),
                title: '居民安全意识薄弱，知识普及通知缺乏强触达场景',
                text: '报警人缺乏专业知识、紧张导致报警信息描述不清楚，消防人员核实信息和救援时间成本高，延误救援。无有效事前通知预警，传统方式接收度不广泛。'
              },
              {
                icon: require('../assets/images/Solution/icon8-2.png'),
                title: '准确定位难',
                text: '发现险情时，隐患通知往往局限于本地报警且缺乏精准定位，导致出警慢，错过最佳援救时机'
              }
            ]
          },
          Solutions: [
            {
              icon: require('../assets/images/Solution/icon8-3.png'),
              title: '一键上报：',
              text: '过5G消息主动触发、强触发的能力，结合大数据应用，可以有效的针对精准目标人群、高危人群进行灾害预警、防火宣传、防灾宣传、安全生产、安全交通等宣传，引导安全隐患积极举报。'
            },
            {
              icon: require('../assets/images/Solution/icon8-4.png'),
              title: '知识普及：',
              text: '通过5G消息强触达的能力，可针对村民进行灾害预警、防火宣传、防灾减灾、安全生产、交通安全、防溺水等宣传通知。'
            },
            {
              icon: require('../assets/images/Solution/icon8-5.png'),
              title: '知识竞答：',
              text: '通过5G消息的互动能力，可以针对特定人群开展防灾减灾宣传以及法律法规宣传学习的效果验证，特定高危人群可通过互动答题等方式完成答题考核，以检验学习效果。'
            },
            {
              icon: require('../assets/images/Solution/icon8-6.png'),
              title: '学点考核：',
              text: '利用5G消息的交互能力，可以通过完成问卷的方式督促高危人员的防灾减灾知识、规范、流程的学习和考核。'
            },
            {
              icon: require('../assets/images/Solution/icon8-7.png'),
              title: '消防资讯：',
              text: '打造基于5G消息的消防应急应用，为全国超过1800万5G消息终端用户提供高效便捷的应急报警服务，实现事故隐患上报、应急知识科普和应急资讯与政策法规查询。'
            }
          ]
        }
      ],
      IndustryPainPoints: {},
      Solutions: {}
    }
  },
  mounted() {
    if (this.$store.state.childSelected === '') {
      this.init()
    }
    this.activeIndex = this.$store.state.childSelected
  },
  watch: {
    $route(to) {
      if (to.name === 'Solution') {
        this.init()
      }
    },
    activeIndex(e) {
      this.$store.commit('SETCHILDSELECTED', e)
    }
  },
  methods: {
    init() {
      if (this.$store.state.childSelected === '') {
        this.$store.commit('SETCHILDSELECTED', 0)
      }
      this.activeIndex = this.$store.state.childSelected
    },
    tabClick(e) {
      this.$store.commit('SETCHILDSELECTED', e)
      this.activeIndex = e
      console.log(
        'clientWidth',
        this.$refs['SideslipBox' + e][0].clientWidth,
        e
      )
      let clientWidth = this.$refs['SideslipBox' + e][0].clientWidth

      if (e <= 2) {
        this.$refs.SideslipBox.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
      } else {
        this.$refs.SideslipBox.scrollTo({
          left: clientWidth * (e - 2),
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    .title {
      font-size: 72px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e8efff;
      line-height: 67px;
    }
    .sub-title {
      margin-top: 52px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e8efff;
      opacity: 0.7;
    }
  }
}
.tab-box {
  text-align: center;
  margin: 0 auto;
  margin-top: 99px;
  .tab-box-content {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    font-size: 20px;
    background: #f9f9f9;
    .tab-box-content-list {
      padding: 18px 25px;
      cursor: pointer;
      &:hover {
        color: #597ef7;
      }
      &.tab-box-active {
        background: #597ef7;
        border-radius: 4px;
        color: #ffffff;
      }
    }
  }
}
.section {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  .title {
    margin-top: 102px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #00205c;
    line-height: 50px;
  }
  .IndustryPainPoints {
    display: flex;
    img {
      width: 400px;
      height: 500px;
    }
    .content {
      margin-top: 90px;
      margin-left: 99px;
      .list-item {
        display: flex;
        margin-bottom: 55px;
        img {
          width: 40px;
          height: 40px;
          margin-right: 20px;
        }
        .list-box {
          text-align: left;
        }
        .list-title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
        }
        .list-text {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 36px;
        }
      }
    }
  }
  .Solutions {
    margin-top: 115px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 137px;

    .list-item {
      display: flex;
      width: 48%;
      margin-bottom: 55px;
      img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
      .list-box {
        text-align: left;
      }
      .list-title {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 28px;
      }
      .list-text {
        margin-top: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .tab-box .tab-box-content {
    font-size: 16px;
  }
}
@media screen and (max-width: 960px) {
  .tab-box .tab-box-content {
    font-size: 14px;
  }
}
</style>

<!-- 移动端样式 -->
<style lang="scss" scoped>
/* 屏幕小于750px */
@media screen and (max-width: 750px) {
  .page {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    /* min-width: 1000px; */
    margin: 0 auto;
    padding: 0 4vw;
    font-family: 'PingFang SC,Microsoft YaHei';
  }
  .flex-box {
    // display: flex;
  }
  .tab-box {
    // width: 400px;

    margin: 0;
    margin-top: 80px;
    .tab-box-content {
      display: flex;
      // width: 300px;
      // flex-wrap: wrap;
      // flex-direction: row-reverse;
      align-items: center;
      border-radius: 4px;
      overflow: hidden;
      font-size: 66px;
      background: #f9f9f9;
      overflow: hidden;
      overflow-x: auto;
      padding-bottom: 20px;
      .tab-box-content-list {
        padding: 50px 50px;
        cursor: pointer;
        flex-shrink: 0;
        &:hover {
          color: #597ef7;
        }
        &.tab-box-active {
          background: #597ef7;
          border-radius: 4px;
          color: #ffffff;
        }
      }
    }
  }
  .section {
    margin: 0;
    max-width: 100%;
    padding: 0 4vw;
    flex: 1;

    .title {
      font-size: 76px;
      line-height: 76px;
    }
    .IndustryPainPoints {
      display: block;
      .content {
        margin-left: 0;
        .list-item {
          .list-title {
            font-size: 60px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: normal;
          }
          .list-text {
            font-size: 56px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: normal;
          }
          img {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .Solutions {
      .list-item {
        width: 100%;
        img {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        .list-title {
          font-size: 60px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: normal;
        }
        .list-text {
          font-size: 56px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: normal;
        }
      }
    }
  }
}
</style>
