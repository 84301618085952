<template>
  <div class="login flex-col" :style="loginbgurl">
    <div class="mod1 flex-col">
      <div class="bd1 flex-col">
        <img
          class="pic1"
          referrerpolicy="no-referrer"
          src="../assets/images/login/logo.png"
        />
        <span class="info1"
          ><span @click="goHome" class="cursor-pointer">官网</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;开发者中心</span
        >
        <div class="main1 flex-col">
          <div class="login-way">
            <el-tabs
              stretch
              v-model="activeName"
              @tab-click="handleClick"
              class="lrMainWrap"
            >
              <el-tab-pane label="账号密码登录" name="password">
                <el-form
                  :model="passwordForm"
                  :rules="passwordFormRules"
                  class="passwordForm"
                >
                  <el-form-item prop="name">
                    <el-input
                      v-model="passwordForm.name"
                      placeholder="集团编号"
                      class="inputHeigth inputWidth"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="name">
                    <el-input
                      v-model="passwordForm.name"
                      placeholder="用户名"
                      class="inputHeigth inputWidth"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="name">
                    <el-input
                      v-model="passwordForm.name"
                      placeholder="请输入密码"
                      class="inputHeigth inputWidth"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="name">
                    <el-input
                      v-model="passwordForm.name"
                      placeholder="请输入验证码"
                      class="inputHeigth"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="手机号码登录" name="phone">
                <el-form
                  :model="passwordForm"
                  :rules="passwordFormRules"
                  class="passwordForm"
                >
                  <el-form-item prop="name">
                    <el-input
                      v-model="passwordForm.name"
                      placeholder="请输入手机号码"
                      class="inputHeigth inputWidth"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="name">
                    <el-input
                      v-model="passwordForm.name"
                      placeholder="请输入验证码"
                      class="inputHeigth"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
            </el-tabs>
            <div class="submit">
              <p>登 录</p>
            </div>
            <span class="forgotpwd" v-show="activeName == 'password'"
              >忘记密码</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
class PasswordLogin {
  constructor() {
    this.name = ''
    this.region = ''
    this.date1 = ''
    this.date2 = ''
    this.delivery = false
    this.type = []
    this.resource = ''
    this.desc = ''
  }
  static PasswordLoginRule() {
    return {
      name: [
        { required: true, message: '请输入活动名称', trigger: 'blur' },
        { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
      ],
      region: [
        { required: true, message: '请选择活动区域', trigger: 'change' }
      ],
      date1: [
        {
          type: 'date',
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }
      ],
      date2: [
        {
          type: 'date',
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }
      ],
      type: [
        {
          type: 'array',
          required: true,
          message: '请至少选择一个活动性质',
          trigger: 'change'
        }
      ],
      resource: [
        { required: true, message: '请选择活动资源', trigger: 'change' }
      ],
      desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
    }
  }
}
import { ref, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup() {
    let router = useRouter()
    console.log(router.push)
    let loginbgurl = {
      backgroundImage: 'url(' + require('../assets/logo-bg.png') + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '100vh',
      position: 'relative',
      backgroundPosition: 'center center'
    }
    let activeName = ref('password')
    let froms = reactive({
      passwordForm: new PasswordLogin(),
      passwordFormRules: PasswordLogin.PasswordLoginRule()
    })
    const handleClick = (val) => {
      console.log(val)
    }
    const goHome = (val) => {
      console.log(val)
      router.push('/home')
    }

    return {
      activeName,
      ...toRefs(froms),
      loginbgurl,
      handleClick,
      goHome
    }
  },

  methods: {}
}
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  background: blue;
  overflow: hidden;

  .mod1 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .bd1 {
      width: 1069px;
      //   height: 741px;
      margin: 144px auto;
      //   position: relative;
      .pic1 {
        width: 179px;
        height: 41px;
      }
      .info1 {
        width: 168px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        letter-spacing: 0.39375001192092896px;
        white-space: nowrap;
        line-height: 25px;
        text-align: center;
        margin: 12px 0 0 901px;
      }
      .main1 {
        z-index: 10;
        overflow: hidden;
        border-radius: 16px;
        height: 500px;
        background-size: 1069px 643px;
        background: url(../assets/images/login/left.png)
          100% no-repeat;
        margin-top: 20px;
        width: 1069px;

        .login-way {
          float: right;
          width: 50%;
          .lrMainWrap {
            width: 432px;
            margin: 0 auto;
            margin-top: 33px;
            ::v-deep .el-tabs__item {
              width: 120px;
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 28px;
              margin-bottom: 10px;

              //   &:hover {
              //     color: $DefaultColor;
              //   }
            }
            ::v-deep .el-tabs__active-bar {
              background: #333333;
              width: 120px;
              height: 4px;
            }
            ::v-deep #tab-password {
              height: 34px;
              border-right: 1px solid #e3e3e3;
              border-right-width: 1px;
            }
          }
          ::v-deep .passwordForm {
            margin: 10px;
          }
          .inputHeigth {
            height: 46px;
          }
          .inputWidth {
            width: 432px;
          }
        }
      }
    }
  }
}
.submit {
  margin: 0 auto;
  width: 412px;
  height: 58px;
  background: linear-gradient(90deg, #3652e1 0%, #0f1798 100%);
  display: flex;
  justify-content: center;
  p {
    text-align: center;
    // width: 36px;
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
.forgotpwd {
  display: block;
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #bcbcbc;
  line-height: 22px;
  float: right;
  margin-right: 62px;
  margin-top: 28px;
}
</style>
