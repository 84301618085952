<template lang="">
  <!-- 头部 -->
  <Header></Header>
  <!-- 内容 -->
  <router-view />
  <!-- 底部 -->
  <Footer></Footer>
</template>
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>
